import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import * as styles from "./style.module.scss"
import Heading from "../../images/problem/heading.svg"
import Image01 from "../../images/problem/img01.svg"
import Triangle from "../../images/problem/img02.svg"
import Icon01 from "../../images/problem/icon01.svg"
import Icon02 from "../../images/problem/icon02.svg"
import Icon03 from "../../images/problem/icon03.svg"

// data
const heading = {
  heading: 'problem',
  main: `オフィス移転する\n企業からよく聞く声`
}
const list = [
  {
    pc: {
      text: `これから<b>増員</b>するので移転したい。`
    },
    sp: {
      text: `これから<b>増員</b>するので移転したい。`
    }
  },
  {
    pc: {
      text: `<b>採用</b>に力を入れるので、より良いオフィス環境にしたい。`,
      sub: '（トイレやパントリーなど、女性スタッフも増えるのできれいなオフィスへ）'
    },
    sp: {
      text: `<b>採用</b>に力を入れるので、\nより良いオフィス環境にしたい。`
    },
    sub: '（トイレやパントリーなど、女性スタッフも増えるのできれいなオフィスへ）'
  },
  {
    pc: {
      text: `テレワーク、ウェブ会議など働き方が変わったので、\nオフィスも<b>新しい働き方</b>に合わせたい。`
    },
    sp: {
      text: `テレワーク、ウェブ会議など\n働き方が変わったので、オフィスも\n<b>新しい働き方</b>に合わせたい。`
    }
  }
]
const note = '※ 実際に移転した企業への移転理由のヒアリング調査による共通した課題'
const icons = [
  {
    text: {
      pc: `<b>採用・人材確保</b>\nへの強いニーズ`,
      sp: `<b>採用・人材確保</b>\nへの強いニーズ`
    },
    icon: Icon01
  },
  {
    text: {
      pc: `オフィスに来る\n<b>意義・目的</b>\nの明確化`,
      sp: `オフィスに来る\n<b>意義・目的</b>の明確化`
    },
    icon: Icon02
  },
  {
    text: {
      pc: `<b>創造性・生産性</b>\nの向上`,
      sp: `<b>創造性・生産性</b>\nの向上`
    },
    icon: Icon03
  }
]

type Props = {
  isDesktop: boolean
}

// markup
const Problem = (props: Props) => {
  return (
    <div className={styles.problem}>
      <div className={styles.problemInner}>
        <div className={styles.headings}>
          <div className={styles.heading}>
            <img src={Heading} alt={heading.heading} />
          </div>
          <h2 className={styles.title}>
            {heading.main}
          </h2>
        </div>
        
        <div className={styles.problemContents}>
          <div className={styles.listContent}>
            <ul className={styles.listItems}>
              {list.map(item => (
                <li key={item.pc.text} className={styles.listItem}>
                  <div>{props.isDesktop ? ReactHtmlParser(item.pc.text) : ReactHtmlParser(item.sp.text)}</div>
                  <span>{item.sub}</span>
                </li>
              ))}
            </ul>
            <div className={styles.problemImage}>
              <img src={Image01} alt="" />
            </div>
          </div>
          <p className={styles.problemNote}>{note}</p>
          <div className={styles.triangle}>
            <img src={Triangle} alt="" />
          </div>
          <div className={styles.iconItems}>
            {icons.map(item => (
              <div key={item.text.pc} className={styles.iconItem}>
                <span>{ props.isDesktop ? ReactHtmlParser(item.text.pc) : ReactHtmlParser(item.text.sp)}</span>
                <div><img src={item.icon} alt="" /></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Problem
