import * as React from "react"
import * as styles from "./style.module.scss"
import Heading from "../../images/about/heading.svg"
import imagePC from '../../images/about/imagePC.svg'
import imageSP from '../../images/about/imageSP.svg'

// data
const heading = {
  heading: 'what’s EFFICE',
  main: `EFFICEとは`
}

type Props = {
  isDesktop: boolean
}

// markup
const About = (props: Props) => {
  return (
    <div className={styles.about}>
      <div className={styles.headings}>
        <div className={styles.heading}>
          <img src={Heading} alt={heading.heading} />
        </div>
        <h2 className={styles.title}>
          {heading.main}
        </h2>
      </div>
      <div className={styles.image}>
        <img src={props.isDesktop ? imagePC : imageSP} alt="" />
      </div>
    </div>
  )
}

export default About
