import * as React from "react"
import useMedia from "use-media"
import { useInView } from 'react-intersection-observer'
import "modern-css-reset"
import "../styles/global/_styles.scss"

import Seo from "../components/Seo/index"
import Header from "../components/Header/index"
import MainVisual from "../components/MainVisual/index"
import Problem from "../components/Problem/index"
import Concept from "../components/Concept/index"
import Features from "../components/Features/index"
import About from "../components/About/index"
import LineUp from "../components/LineUp/index"
import Contact from "../components/Contact/index"
import Footer from "../components/Footer/index"


// markup
const IndexPage = () => {
  const isDesktop = useMedia({ minWidth: "768px" });
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const meta = {
    title: 'EFFICE | 人が集まる、生産性が上がるワンランクアップなオフィス',
    description: 'EFFICEは成長する企業を支える新しいコンセプトのオフィス。人の採用やチームの結束力、業務の効率性を高める効果だけでなく、環境負荷を削減できるよう設計。',
    path: '/'
  }

  return (
    <main>
      <Seo title={meta.title} description={meta.description} path={meta.path} />
      <Header isDesktop={isDesktop} hidden={inView} />
      <MainVisual isDesktop={isDesktop} />
      <Problem isDesktop={isDesktop} />
      <Concept />
      <Features isDesktop={isDesktop} />
      <About isDesktop={isDesktop} />
      <LineUp />
      <div ref={ref}><Contact isDesktop={isDesktop} /></div>
      <Footer />
    </main>
  )
}

export default IndexPage
