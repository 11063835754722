// extracted by mini-css-extract-plugin
export var heading = "style-module--heading--N6ys3";
export var headings = "style-module--headings--O8Z74";
export var iconItem = "style-module--iconItem--qmbS-";
export var iconItems = "style-module--iconItems--ZIfmy";
export var listContent = "style-module--listContent--fYuyO";
export var listItem = "style-module--listItem--GtTLK";
export var listItems = "style-module--listItems--SWfBq";
export var problem = "style-module--problem--ypOh-";
export var problemContents = "style-module--problemContents--SsMns";
export var problemImage = "style-module--problemImage--IUuFu";
export var problemInner = "style-module--problemInner--4UQIW";
export var problemNote = "style-module--problemNote--zsHV1";
export var title = "style-module--title--1E4AK";
export var triangle = "style-module--triangle--ChnfC";