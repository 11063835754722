// extracted by mini-css-extract-plugin
export var closeButton = "style-module--closeButton--v10qB";
export var content = "style-module--content--YdtkV";
export var contentDescription = "style-module--contentDescription--cbrD2";
export var contentHeading = "style-module--contentHeading---QeBj";
export var contentInner = "style-module--contentInner--jhQBY";
export var contentLink = "style-module--contentLink--Rhgdn";
export var contentNum = "style-module--contentNum--djbq0";
export var feature = "style-module--feature--dpXnI";
export var features = "style-module--features--6unKC";
export var image = "style-module--image--dKOR0";
export var modal = "style-module--modal--IGOH1";
export var modalContent = "style-module--modalContent--tYCmb";
export var modalContentHeading = "style-module--modalContentHeading--rzuWG";
export var modalContentReverse = "style-module--modalContentReverse--DyLDg";
export var modalContentText = "style-module--modalContentText--jsSnq";
export var modalContents = "style-module--modalContents--FURa6";
export var modalHeader = "style-module--modalHeader--92SL-";
export var modalOverlay = "style-module--modalOverlay--xwORp";