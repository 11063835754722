import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import Modal from "react-modal"

import * as styles from "./style.module.scss"
import Num01 from '../../images/features/01.svg'
import Image01 from '../../images/features/image01.jpg'
import Num02 from '../../images/features/02.svg'
import Image02 from '../../images/features/image02.jpg'
import Num03 from '../../images/features/03.svg'
import Image03 from '../../images/features/image03.jpg'
import Num04 from '../../images/features/04.svg'
import Image04 from '../../images/features/image04.jpg'
import ViewMore from '../../images/features/viewMore.svg'

// modal images
import Modal01Image01PC from '../../images/features/modal/modal01_01-pc.png'
import Modal01Image01SP from '../../images/features/modal/modal01_01-sp.png'
import Modal01Image02PC from '../../images/features/modal/modal01_02-pc.svg'
import Modal01Image02SP from '../../images/features/modal/modal01_02-sp.svg'
import Modal02Image01 from '../../images/features/modal/modal02_01.jpg'
import Modal02Image02 from '../../images/features/modal/modal02_02.jpg'
import Modal03Image01 from '../../images/features/modal/modal03_01.jpg'
import Modal03Image02 from '../../images/features/modal/modal03_02.jpg'
import Modal04Image01PC from '../../images/features/modal/modal04_01-pc.png'
import Modal04Image01SP from '../../images/features/modal/modal04_01-sp.png'
import Modal04Image02PC from '../../images/features/modal/modal04_02-pc.png'
import Modal04Image02SP from '../../images/features/modal/modal04_02-sp.png'

Modal.setAppElement("#___gatsby");

// data
const features = [
  {
    modalKey: 'costCut',
    number: Num01,
    image: Image01,
    heading: "賢くコストカット",
    description: `内装、家具付きで初期投資を低く抑えられる。\n深く考えられたレイアウトにより、働き方に柔軟性を持たせ、通常より業務席数の1.5倍の人数を収容することができ、実質利用面積の削減により賃料も削減。それに加え、原状回復費用と期間も大幅に削減できる。`
  },
  {
    modalKey: 'rankUp',
    number: Num02,
    image: Image02,
    heading: "会社のイメージをワンランクアップ",
    description: `これから人を採用していくためには、会社のイメージをワンランクアップし、魅力あるオフィスが必要不可欠。\n美しくデザインされたエントランス、オフィス空間が訪れた人たちへの企業の信用度を高めてくれます。`
  },
  {
    modalKey: 'workStyle',
    number: Num03,
    image: Image03,
    heading: `コミュニケーションの促進と\n業務効率化を両立したこれからの働き方`,
    description: `オフィスに来て会って話すことの価値。社員同士のディスカッションがしやすいギャザリングスペースが多く配置。\n自宅でテレワーク、社外とのオンライン会議、オフィスでの作業を想定した全席フリーデスク、ウェブミーティングブース、パーソナルロッカーがあり、様々な働き方で活用ができます。`
  },
  {
    modalKey: 'eco',
    number: Num04,
    image: Image04,
    heading: `環境負荷を減らす取り組みを`,
    description: `通常のオフィスは新しく内装をし、また元に戻して返却するため、多くの廃棄材が発生。利用可能な内装・設備を次のテナントに継承することで大幅な廃棄物の削減し、無駄なゴミを減らします。利用する家具や建材もなるべく長く使えるものや再利用できる仕組みにしています。`,
  }
]

const firstModal = {
  number: Num01,
  heading: '賢くコストカット',
  contents: [
    {
      image: {
        pc: Modal01Image01PC,
        sp: Modal01Image01SP
      },
      heading: 'よく考えられた設計・内装家具付き',
      text: `内装・家具付きで移転を低く抑えることが可能です。\nレイアウトの工夫により、働き方に柔軟性を持たせながら、従来比約1.5倍の収容人数を実現し、実質賃料を削減します。`,
      isReverse: false
    },
    {
      image: {
        pc: Modal01Image02PC,
        sp: Modal01Image02SP
      },
      heading: 'スタッフ25人、3年間の賃貸オフィスを検討する場合',
      isReverse: true
    }
  ]
}

const secondModal = {
  number: Num02,
  heading: '会社のイメージをワンランクアップ',
  contents: [
    {
      image: Modal02Image01,
      heading: '魅力的な空間作り',
      text: `これから人を採用していくためには、会社のイメージをワンランクアップし、魅力あるオフィスが必要不可欠。`,
      isReverse: false
    },
    {
      image: Modal02Image02,
      heading: '充実したコミュニーティスペース',
      text: '人が自然と集まる空間をつくることで、リアルコミュニケーションが生まれ、生産性の最大化につながります。',
      isReverse: false
    }
  ]
}

const thirdModal = {
  number: Num03,
  heading: `コミュニケーションの促進と\n業務効率化を両立したこれからの働き方`,
  contents: [
    {
      image: Modal03Image01,
      heading: 'オフィスで会って話すことの価値',
      text: `社員同士のディスカッションがしやすいギャザリングスペースを多く配置しています。`,
      isReverse: false
    },
    {
      image: Modal03Image02,
      heading: 'テレワークやオンライン会議をやりやすく',
      text: `自宅でテレワーク、社外とのオンライン会議、オフィスでの作業を想定した全席フリーデスク、ウェブミーティングブース、パーソナルロッカーがあり、様々な働き方を選択することができます。`,
      isReverse: false
    }
  ]
}

const fourthModal = {
  number: Num04,
  heading: `環境負荷を減らす取り組みを`,
  contents: [
    {
      image: {
        pc: Modal04Image01PC,
        sp: Modal04Image01SP
      },
      heading: '廃棄材を削減する取り組み',
      text: `通常のオフィスは新しく内装をし、また元に戻して返却するため、多くの廃棄材が発生。利用可能な内装・設備を次のテナントに継承することで大幅な廃棄物の削減し、無駄なゴミを減らします。`,
      isReverse: false
    },
    {
      image: {
        pc: Modal04Image02PC,
        sp: Modal04Image02SP
      },
      heading: 'EEFICEの空間をつくるサスティナブルな材料',
      text: `壁面に廃棄物をリサイクルしてつくられ た材料や廃棄衣類から作られたリサイクル ボードを使用する等、EFFICEはリサイクル材料や自然の素材をなるべく取り入れています（各物件によって取り入れている内容は異なります）。`,
      isReverse: false
    }
  ]
}

type Props = {
  isDesktop: boolean
}

// markup
const Features = (props: Props) => {
  const [modalIsOpen, setIsOpen] = React.useState('');
  const handleOpenModal = (key: string) => {
    setIsOpen(key) 
  }
  const handleCloseModal = () => {
    setIsOpen('')
  }
  return (
    <div className={styles.features}>
      {features.map(item => (
        <div key={item.modalKey} className={styles.feature}>
          <div className={styles.image}><img src={item.image} alt={item.heading} /></div>
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <div className={styles.contentNum}><img src={item.number} alt="" /></div>
              <h3 className={styles.contentHeading}>{ReactHtmlParser(item.heading)}</h3>
              <p className={styles.contentDescription}>{ReactHtmlParser(item.description)}</p>

              <a className={styles.contentLink} onClick={() => handleOpenModal(item.modalKey)}>
                <img src={ViewMore} alt="VIEW MORE" />
                <span></span>
              </a>
            </div>  
          </div>
        </div>
      ))}
      <Modal 
        isOpen={(modalIsOpen === features[0].modalKey)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div>
          <div className={styles.modalHeader}>
            <div><img src={firstModal.number} alt="" /></div>
            <h1>
              {firstModal.heading}
            </h1>
          </div>
          <div className={styles.modalContents}>
            {firstModal.contents.map(content => (
              <div key={content.heading} className={content.isReverse ? styles.modalContentReverse : styles.modalContent}>
                <div><img src={props.isDesktop ? content.image.pc : content.image.sp} alt="" /></div>
                <div>
                  <h2 className={styles.modalContentHeading}>{content.heading}</h2>
                  <p className={styles.modalContentText}>{content.text}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.closeButton} onClick={() => handleCloseModal()}>閉じる</div>
        </div>
      </Modal>
      <Modal 
        isOpen={(modalIsOpen === features[1].modalKey)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div>
          <div className={styles.modalHeader}>
            <div><img src={secondModal.number} alt="" /></div>
            <h1>
              {secondModal.heading}
            </h1>
          </div>
          <div className={styles.modalContents}>
            {secondModal.contents.map(content => (
              <div key={content.heading} className={content.isReverse ? styles.modalContentReverse : styles.modalContent}>
                <div><img src={content.image} alt="" /></div>
                <div>
                  <h2 className={styles.modalContentHeading}>{content.heading}</h2>
                  <p className={styles.modalContentText}>{content.text}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.closeButton} onClick={() => handleCloseModal()}>閉じる</div>
        </div>
      </Modal>
      <Modal 
        isOpen={(modalIsOpen === features[2].modalKey)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div>
          <div className={styles.modalHeader}>
            <div><img src={thirdModal.number} alt="" /></div>
            <h1>
              {thirdModal.heading}
            </h1>
          </div>
          <div className={styles.modalContents}>
            {thirdModal.contents.map(content => (
              <div key={content.heading} className={content.isReverse ? styles.modalContentReverse : styles.modalContent}>
                <div><img src={content.image} alt="" /></div>
                <div>
                  <h2 className={styles.modalContentHeading}>{content.heading}</h2>
                  <p className={styles.modalContentText}>{content.text}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.closeButton} onClick={() => handleCloseModal()}>閉じる</div>
        </div>
      </Modal>
      <Modal 
        isOpen={(modalIsOpen === features[3].modalKey)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div>
          <div className={styles.modalHeader}>
            <div><img src={fourthModal.number} alt="" /></div>
            <h1>
              {fourthModal.heading}
            </h1>
          </div>
          <div className={styles.modalContents}>
            {fourthModal.contents.map(content => (
              <div key={content.heading} className={content.isReverse ? styles.modalContentReverse : styles.modalContent}>
                <div><img src={props.isDesktop ? content.image.pc : content.image.sp} alt="" /></div>
                <div>
                  <h2 className={styles.modalContentHeading}>{content.heading}</h2>
                  <p className={styles.modalContentText}>{content.text}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.closeButton} onClick={() => handleCloseModal()}>閉じる</div>
        </div>
      </Modal>
    </div>
  )
}

export default Features
