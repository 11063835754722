import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import * as styles from "./style.module.scss"
import Heading from "../../images/concept/heading.svg"
import Image01 from "../../images/concept/img01.jpg"

// data
const heading = {
  heading: 'concept',
  main: `成長する企業を支える\n新しいコンセプトのオフィス`
}
const paragraphs = [
  '組織が大きくなるにつれ、働き方や暮らし方が多様化する一方で、チームの結束力や業務の効率性はより重要になってくる。',
  'EFFICEは、成長する企業を支えるため「コラボレーション」「テレワーク」「ウェブ会議」といった新しい働き方に適したオフィスを実現。',
  `自然とコミュニケーションが生まれる、いままで以上のポテンシャルを発揮できる、出社する意味を皆が感じられる。\nEFFICEは「企業と共に成長する」オフィスを提供します。`
]

// markup
const Concept = () => {
  return (
    <div className={styles.concept}>
      <div className={styles.contents}>
        <div className={styles.headings}>
          <div className={styles.heading}>
            <img src={Heading} alt={heading.heading} />
          </div>
          <h2 className={styles.title}>
            {heading.main}
          </h2>
        </div>
        <div className={styles.text}>
          {paragraphs.map(item => (
            <p key={item}>{ReactHtmlParser(item)}</p>
          ))}
        </div>
      </div>
      <div className={styles.image}>
        <img src={Image01} alt="" />
      </div>
    </div>
  )
}

export default Concept