import * as React from "react"
import * as styles from "./style.module.scss"
import Scroll from "../../images/mainVisual/scroll.svg"
import LeadSP from "../../images/mainVisual/lead-sp.svg"
import LeadPC from "../../images/mainVisual/lead-pc.svg"

// data
const copy = {
  main: `人が集まる\n生産性が上がる\nワンランクアップなオフィス`,
  sub: 'A higher-grade office where people gather and productivity increases'
}

type Props = {
  isDesktop: boolean
}

// markup
const MainVisual = (props: Props) => {
  return (
    <div className={styles.mainVisual}>
      <div className={styles.copy}>
        <h1 className={styles.copy__main} >{copy.main}</h1>
        <span className={styles.copy__sub} ><img src={props.isDesktop ? LeadPC : LeadSP} alt={copy.main} /></span>
      </div>
      <div className={styles.scrollDown}><span><img src={Scroll} alt="scroll" /></span></div>
    </div>
  )
}

export default MainVisual
